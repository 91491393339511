@import "~jbc-front/styles/core";

:local {

  .marginSidebar {
    margin-left: 50px;
  }
  .main {
    position: relative;
    background: #f1f1f1;
  }

  @media #{$smartphone} {
    .marginSidebar {
      margin-left: 0;
    }
  }

  .base {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 13px 20px;
    width: 100%;
    background-color: #fff;
    align-items: center;
    color: #000;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #ddd;
    z-index: 100;
  }
}

.zEWidget-launcher {
  bottom: 60px !important;
}

@media #{$smartphone} {
  .zEWidget-launcher {
    right: -5px;
    bottom: 5px !important;
  }
}

