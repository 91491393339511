@import "~jbc-front/styles/core";

:local {
  .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 30px 30px;
    width: 800px;
    background-color: #fff;
  }
  .logo {
    text-align: center;

    svg {
        height: 26px;
    }
  }
  .thanks {
    position: relative;
    margin-top: 80px;
    padding-bottom: 25px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;

    &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        margin-left: -25px;
        width: 50px;
        height: 4px;
        background-color: #101010;
    }
  }
  .text {
    margin-top: 80px;
    font-size : 16px;
    text-align: center;
  }
  .login {
    margin-top: 20px;
    font-size : 16px;
    text-align: center;

    a {
        color: colors(blue);
    }
  }

  @media #{$smartphone} {

    .wrap {
      top: 35%;
      padding: 30px 10px 30px;
      width: 95%;
    }
    .thanks {
      margin-top: 30px;
      font-size: 20px;
    }
    .text {
      margin-top: 40px;
      font-size : 14px;
    }
    .login {
      font-size : 14px;
    }

  }

}
