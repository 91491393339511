@import "~jbc-front/styles/core";

:local {

  .base {
    display: flex;
    flex-wrap: wrap;
    box-shadow: inset 0 1px 0 0 #ddd;
    padding: 0 30px 20px;
    width: 100%;
    height: 56px;
    background-color: #fff;
    color: textColors(sub);
    font-size: 13px;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .menu {
    border-left: 1px solid textColors(sub);
    padding:  0 1em;
    color: textColors(sub);
    text-decoration: none;

    &:hover {
      color: textColors(sub, hover);
    }

    &:first-child {
      padding: 0 1em 0 0;
      border: none;
    }
  }
  .copyright {
    margin-left: auto;
    padding-top: 20px;
  }

  @media #{$smartphone} {

    .base {
      display: block;
      padding: 0 15px 20px;
      height: auto;
    }
    .nav {
      display: block;
      padding-top: 20px;
      text-align: center;
    }
    .menu {
      position: relative;
      display: inline-block;
      border-left: none;
      padding:  0 10px 8px;
      text-align: center;

      &:after {
        position: absolute;
        content: '';
        top: 0.2em;
        left: 0;
        width: 1px;
        height: 0.9em;
        background-color: #ddd;
      }

      &:first-child {
        &:after {
          background-color: #fff;
        }
      }
    }
    .copyright {
      margin-left: 0;
      text-align: center;
    }

  }

}

